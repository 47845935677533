<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.new_m", {
                m: $t("message.comment"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end :rules="rules" :rows="5"-->

      <div class="app-modal__body p-5 pb-0 mt-5">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" label-position="top">
            <el-row :gutter="20">
               <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item
							:label="$t('message.comment')"
							prop="comment"
							class="label_mini"
							:rules="{required: true, message: $t('message.please_enter_input', {input: $t('message.comment')}), trigger: 'change'}"
						>
							<el-input
								type="textarea"
								:rows="10"
								:placeholder="$t('message.comment')"
								v-model="form.comment"
							>
							</el-input>
						</el-form-item>
               </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";

export default {
	props: {
		selectedItem: {
			type: Object,
			default: null
		}
	},
  	mixins: [drawer],
	data: () => ({
		form: {
			id: null,
			comment: ''
		},
		loadingButton: false,
	}),
	methods: {
		...mapActions({
			save: "clients/storeClientComment",
		}),
		afterOpen(){
			if (this.selectedItem.id) this.form.id = this.selectedItem.id;
		},
		submit(close = true){
			this.$refs["form"].validate((valid) => {
			if (valid) {
				this.loadingButton = true;
				this.dataSaved = true;
				this.save(this.form)
					.then((res) => {
					this.loadingButton = false;
					this.$alert(res);
					if (close) this.close();
					})
					.catch((err) => {
					this.loadingButton = false;
					this.$alert(err);
					});
			}
			});
		},
		afterLeave(){
			this.form.client_id = null;
			this.form.comment = '';
		}
	},
};
</script>

<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.service_provider")
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0 mt-5">
        <div class="timeline-items__right rounded-sm w-100 p-4">
			<div class="mb-4">
				<p class="large--title m-0">
					{{ $t('message.provider') + ': ' + selectedItem.full_name }}
				</p>
		  </div>
         <el-form ref="form" label-position="top">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.services')" class="label_mini">
						<select-product-services
							v-model="service_id"
							:id="service_id"
							@c-change="selectedServiceId"
						></select-product-services>
                </el-form-item>
              </el-col>
            </el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<div>
							<el-table :data="items" stripe border style="width: 100%">
								<el-table-column type="index" label="#" width="50">
								</el-table-column>
								<el-table-column :label="$t('message.service')">
									<template slot-scope="item">
										<span v-if="item.row.id">
                                            {{ item.row.product_service ? item.row.product_service.name : ''}}</span>
										<span v-else>{{ item.row.name }}</span>
									</template>
								</el-table-column>
								<el-table-column :label="$t('message.total_price')">
									<template slot-scope="item">
										<crm-money-input
											v-model="item.row.price"
											:old="item.row.price"
											:size="'medium'"
										>
										</crm-money-input>
									</template>
								</el-table-column>
								<el-table-column :label="$t('message.currency')">
									<template slot-scope="item">
										<select-currency
											:size="'medium'"
											:id="item.row.currency_id"
											v-model="item.row.currency_id"
										>
										</select-currency>
									</template>
								</el-table-column>
								<el-table-column :label="$t('message.delete')" width="100">
									<template slot-scope="item">
										<el-button type="danger" @click="removeRow(item.row)" icon="el-icon-delete delete-icon" circle size="small"></el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</el-col>
				</el-row>

          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import selectProductServices from '@/components/inventory/select-product-services';
import selectCurrency from "@/components/inventory/select-currency";
import SelectClient from '@/components/inventory/select-client';

export default {
  	mixins: [drawer],
  	components:{selectProductServices, selectCurrency, SelectClient},
	props: {
		selectedItem: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			form: {},
			loadingButton: false,
			service_id: null,
			items: [],
		};
	},
	computed: {
		...mapGetters({
			services: 'productServices/inventory'
		}),
	},
	methods: {
		...mapActions({
			save: "clients/storeProviderProductServices",
			empty: "clients/empty",
			getProviderProductServices: 'clients/getProviderProductServices',
		}),
		afterOpen(){
			this.load();
		},
		load(){
			if (!this.loadingData && this.selectedItem) {
				this.getProviderProductServices(this.selectedItem.id).then((res) => {
					this.items = JSON.parse(JSON.stringify(res.list));
				}).catch((err) => {
					this.$alert(err);
				});
			}
		},
		submit(close = true) {
			if(!_.isEmpty(this.items)){
				this.loadingButton = true;
				this.form.id = this.selectedItem.id;
				this.form['list'] = this.items.map(item => {
					return {
						product_service_id: item.product_service_id,
						price: item.price,
						currency_id: item.currency_id
					}
				});

				this.save(this.form)
					.then((res) => {
						this.loadingButton = false;
						this.$alert(res);
						this.parent().listChanged();
						if (close) this.close();
					})
					.catch((err) => {
						this.loadingButton = false;
						this.$alert(err);
					});
			}
			else{
					this.$message({
						showClose: true,
						message: this.$t("message.services do not have"),
						type: 'warning',
						duration: 1500
					});
			}
		},
		selectedServiceId(service_id){
			if (!_.find(this.items, {'product_service_id': service_id})) {
				let product_service = _.find(this.services, {'id': service_id});
				if (product_service) {
					this.items.push({
						id: null,
						product_service_id: product_service.id,
						name: product_service.name,
						price: 0,
						currency_id: 1
					});
					this.service_id = null;
				}
			}
			else {
				this.service_id = null;
			}
		},
		removeRow(row){
			if (this.items.length > 0) {
				this.items.splice(this.items.indexOf(row), 1);
			}
		},
		afterLeave(){
			this.empty();
			this.form = {};
			this.items = [];
		}
	}
};
</script>

<style>
.delete-icon{
	color: white;
}
</style>

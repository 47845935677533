<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.services')" filterable clearable
      :size="size" class="w-100" @change="Cchange">
      <el-option v-for="(service,index) in services" :key="'services-' + index" :label="service.name" :value="service.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.services && this.services.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
       services: 'productServices/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'productServices/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      },
		Cchange(e){
			this.$emit('c-change', e);
			this.selected = this.id;
		}
    },
  }

</script>
